import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26d72f2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "dropdown",
  class: "relative text-bg-dark inline-block"
}
const _hoisted_2 = {
  key: 0,
  class: "block text-t-sub font-medium"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      class: "flex justify-between items-center bg-input-light text-t-main rounded-lg font-semibold text-lg px-4 py-3 focus:outline-none w-full h-full",
      type: "button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpen = !_ctx.isOpen))
    }, [
      _createElementVNode("p", {
        class: "text-left transition-all duration-300",
        style: _normalizeStyle({
          marginRight: _ctx.isOpen
            ? `calc(${_ctx.maxOptionWidth} - ${_ctx.visibleOptionWidth})`
            : '0',
        })
      }, _toDisplayString(_ctx.modelValue), 5),
      _createVNode(_component_Icon, {
        class: _normalizeClass(["ml-1 -mr-1 w-6 h-6 transform transition-transform duration-300", { 'rotate-180': _ctx.isOpen }]),
        icon: _ctx.icons.chevronDown
      }, null, 8, ["class", "icon"])
    ]),
    _createVNode(_Transition, { name: "dropdown-open" }, {
      default: _withCtx(() => [
        (_ctx.isOpen)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "dropdown absolute rounded-b-lg bg-input-light text-t-main w-full py-3 pt-1 left-0 top-full -mt-2 z-10 duration-300 transition-all overflow-y-scroll",
              style: _normalizeStyle([{"max-height":"calc(2.5rem * 6)"}, { height: `calc(2.5rem * ${_ctx.options.length - 1} + 1rem)` }])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options.filter((o) => o !== _ctx.modelValue), (option, i) => {
                return (_openBlock(), _createElementBlock("button", {
                  key: i,
                  class: "px-4 font-semibold text-lg py-1.5 w-full text-left focus:outline-none transition-colors duration-300 hover:text-primary-400",
                  type: "button",
                  onClick: ($event: any) => {
            _ctx.$emit('update:modelValue', option);
            _ctx.isOpen = false;
          }
                }, _toDisplayString(option), 9, _hoisted_3))
              }), 128))
            ], 4))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 512))
}