
import { defineComponent, onMounted } from "vue";
import { useStore } from "./store";
import themes from "@/utils/themes";

import CThemeSelector from "./components/shared/CThemeSelector.vue";
import CToastController from "./components/shared/Toast/CToastController.vue";

export default defineComponent({
  name: "App",
  components: { CToastController, CThemeSelector },
  setup() {
    const store = useStore();

    onMounted(() => {
      const html = document.querySelector("html");
      const theme = "light";
      html?.classList.add(theme);

      store.setTheme(theme);
    });
  },
});
