
import { defineComponent } from "vue";

export default defineComponent({
  name: "CGradientHeading",
  components: {},
  props: {
    size: {
      type: Number,
      default: 9,
    },
    noscale: {
      type: Boolean,
      default: false,
    },
    direction: {
      type: String,
      default: "r",
    },
  },
});
