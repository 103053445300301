
import { computed, defineComponent, ref } from "vue";
import useComponentEvent from "@/utils/useComponentEvent";

import { Icon } from "@iconify/vue";
import chevronDownIcon from "@iconify-icons/feather/chevron-down";

export default defineComponent({
  name: "CInputDropdown",
  components: {
    Icon,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    options: {
      type: Array as () => string[],
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const dropdown = ref(document.createElement("div"));
    const isOpen = ref(false);

    useComponentEvent(document.body, "click", (e) => {
      if (
        isOpen.value &&
        e.target &&
        e.target !== dropdown.value &&
        !(
          dropdown.value.compareDocumentPosition(e.target as Node) &
          Node.DOCUMENT_POSITION_CONTAINED_BY
        )
      ) {
        isOpen.value = false;
      }
    });

    const roundLength = (l: number) =>
      Math.round((l + Number.EPSILON) * 100) / 100;

    const maxOptionWidth = computed(() => {
      const max = Math.max(...props.options.map((o) => o.length));
      const correction = Math.sqrt(max) / 3;

      return `${max > 4 ? roundLength(max - correction) : max}ch`;
    });

    const visibleOptionWidth = computed(
      () =>
        `${
          props.modelValue.length > 4
            ? roundLength(
                props.modelValue.length - Math.sqrt(props.modelValue.length) / 2
              )
            : props.modelValue.length
        }ch`
    );

    return {
      dropdown,
      isOpen,

      maxOptionWidth,
      visibleOptionWidth,

      icons: {
        chevronDown: chevronDownIcon,
      },
    };
  },
});
