import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_toast = _resolveComponent("c-toast")!

  return (_ctx.showToast)
    ? (_openBlock(), _createBlock(_component_c_toast, {
        key: 0,
        text: _ctx.currentToast.text,
        duration: _ctx.currentToast.duration,
        onSlideOut: _ctx.removeToast
      }, null, 8, ["text", "duration", "onSlideOut"]))
    : _createCommentVNode("", true)
}