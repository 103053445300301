
import { computed, defineComponent, ref } from "vue";
import { useStore } from "@/store";
import themes from "@/utils/themes";

import CModal from "./Modal/CModal.vue";

import { Icon } from "@iconify/vue";
import themeIcon from "@iconify-icons/feather/layout";

export default defineComponent({
  name: "CThemeSelector",
  inheritAttrs: false,
  components: {
    CModal,
    Icon,
  },
  setup() {
    const store = useStore();

    const showModal = ref(false);

    const chosenTheme = computed(() => store.theme);

    const changeTheme = (theme: string) => {
      if (!themes.includes(theme)) return;

      const html = document.querySelector("html");
      if (html) {
        html.classList.remove(store.theme);
        html.classList.add(theme);

        store.setTheme(theme);
      }
    };

    return {
      showModal,

      themes,
      chosenTheme,
      changeTheme,

      icons: {
        theme: themeIcon,
      },
    };
  },
});
