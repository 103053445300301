import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("h1", {
    class: _normalizeClass(["pb-2 font-bold bg-clip-text text-transparent from-primary-600 to-accent-500", 
      'bg-gradient-to-' +
      _ctx.direction +
      ' ' +
      (_ctx.noscale
        ? `text-${_ctx.size}xl`
        : `text-${Math.ceil(_ctx.size - _ctx.size / 4)}xl md:text-${_ctx.size}xl`)
    ])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}