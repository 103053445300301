import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_c_button = _resolveComponent("c-button")!

  return (_openBlock(), _createBlock(_component_c_button, { class: "flex gap-2" }, {
    default: _withCtx(() => [
      _createVNode(_component_Icon, { icon: _ctx.icon }, null, 8, ["icon"]),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}