
import { IconifyIcon } from "@iconify/vue";
import { defineComponent } from "vue";

import { Icon } from "@iconify/vue";

import CButton from "./CButton.vue";

export default defineComponent({
  name: "CButtonIcon",
  components: {
    CButton,
    Icon,
  },
  props: {
    icon: {
      type: Object as () => IconifyIcon,
      required: true,
    },
  },
});
