
import { defineComponent, nextTick, ref } from "vue";

import Haggis from "Haggis";
import { escapeHTML } from "@/utils/escape";

import CButtonText from "@/components/shared/Button/CButtonText.vue";

export default defineComponent({
  name: "HEditorTerminal",
  components: { CButtonText },
  setup() {
    const output = ref("");
    const input = ref("");
    const showInput = ref(false);

    let readlineResolver: (s: string | PromiseLike<string>) => void;
    const submitInput = () => {
      readlineResolver(input.value);
      showInput.value = false;
      input.value = "";
    };

    Haggis.addLogListener((msg) => {
      output.value += escapeHTML(msg);
    });

    Haggis.addErrorListener((token, msg) => {
      output.value += `<span class="text-rose-700 whitespace-normal">[line ${token.line}] Error: ${msg}</span><br><br>`;
    });

    Haggis.reader = {
      readline: () => {
        showInput.value = true;
        nextTick(() => document.getElementById("terminalInput").focus());

        return new Promise<string>((resolve) => {
          readlineResolver = resolve;
        });
      },
    };

    return {
      output,
      input,
      showInput,
      submitInput,
    };
  },
});
