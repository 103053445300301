import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-de31a1b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col flex-grow gap-3" }
const _hoisted_2 = { class: "flex h-14" }
const _hoisted_3 = { class: "flex h-full w-52 rounded-lg bg-input-light text-t-main font-bold font-mono text-lg gap-4 p-2" }
const _hoisted_4 = { class: "flex-grow relative overflow-hidden" }
const _hoisted_5 = {
  class: "h-full w-full flex overflow-hidden bg-bg-light border-2 border-b-light rounded-lg font-mono focus-within:border-b-highlight transition-all relative",
  ref: "container"
}
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_h_editor_terminal = _resolveComponent("h-editor-terminal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "h-full flex-grow hover:bg-input-blur-dark rounded-md transition-colors duration-300",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.insertChar('≠')))
        }, " ≠ "),
        _createElementVNode("button", {
          class: "h-full flex-grow hover:bg-input-blur-dark rounded-md transition-colors duration-300",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.insertChar('≤')))
        }, " ≤ "),
        _createElementVNode("button", {
          class: "h-full flex-grow hover:bg-input-blur-dark rounded-md transition-colors duration-300",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.insertChar('≥')))
        }, " ≥ ")
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: "max-w-14 bg-primary-100 text-primary-500 py-3 flex flex-col items-center",
          style: _normalizeStyle({ marginTop: `${-_ctx.scroll.y.value}px` })
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lines, (l, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: i,
              class: "px-3"
            }, _toDisplayString(i + 1), 1))
          }), 128))
        ], 4),
        _createElementVNode("div", {
          class: "relative flex-grow",
          style: _normalizeStyle({ maxWidth: `${_ctx.editorWidth}%` })
        }, [
          _withDirectives(_createElementVNode("textarea", {
            id: "playgroundCode",
            class: "code-container overflow-scroll outline-none bg-transparent resize-none text-transparent caret-t-main h-full w-full absolute top-0 left-0 z-10 p-3 whitespace-nowrap",
            ref: "textarea",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.code) = $event)),
            name: "code",
            autocomplete: "false",
            spellcheck: "false",
            onKeydown: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleEditorKeyDown && _ctx.handleEditorKeyDown(...args)))
          }, null, 544), [
            [_vModelText, _ctx.code]
          ]),
          _createElementVNode("pre", {
            ref: "highlighted",
            class: "code-container absolute top-0 left-0 p-3 w-full h-full whitespace-pre overflow-scroll text-t-main block",
            innerHTML: _ctx.highlightedCode
          }, null, 8, _hoisted_6)
        ], 4),
        _createElementVNode("div", {
          class: _normalizeClass(["absolute z-20 top-0 h-full w-1.5 cursor-col-resize bg-b-dark flex items-center transition-all duration-300", { 'transition-none': _ctx.holdingSeparator }]),
          style: _normalizeStyle({ left: `${_ctx.editorWidth}%` }),
          onMousedown: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.holdingSeparator = true), ["self"]))
        }, [
          _createElementVNode("button", {
            class: "absolute right-full -mr-1 w-7 h-14 bg-b-dark rounded-l-lg text-bg-light cursor-pointer",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (
              _ctx.terminalWidth > 3 ? _ctx.setTerminalWidth(0) : _ctx.setTerminalWidth(33)
            ))
          }, [
            _createVNode(_component_Icon, {
              class: _normalizeClass(["w-full h-full transform transition-transform duration-300", { 'rotate-180': _ctx.terminalWidth > 3 }]),
              icon: _ctx.icons.open
            }, null, 8, ["class", "icon"])
          ])
        ], 38),
        _createVNode(_component_h_editor_terminal, {
          ref: "terminal",
          class: _normalizeClass(["h-full w-full transition-all duration-300 ml-auto", { 'transition-none': _ctx.holdingSeparator }]),
          style: _normalizeStyle({ maxWidth: `${_ctx.terminalWidth}%` })
        }, null, 8, ["class", "style"])
      ], 512)
    ])
  ]))
}