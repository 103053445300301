
import { defineComponent } from "vue";

import { Icon } from "@iconify/vue";
import codeIcon from "@iconify-icons/feather/code";
import githubIcon from "@iconify-icons/feather/github";

import CButton from "@/components/shared/Button/CButton.vue";
import CButtonOutline from "@/components/shared/Button/CButtonOutline.vue";
import CCanvasCircles from "@/components/shared/Canvas/CCanvasCircles.vue";
import CGradientHeading from "@/components/shared/Heading/CGradientHeading.vue";

export default defineComponent({
  name: "Home",
  components: {
    CGradientHeading,
    CButton,
    CButtonOutline,
    CCanvasCircles,
    Icon,
  },
  setup() {
    const openSpec = () => {
      window.open(
        "https://www.sqa.org.uk/sqa/files_ccc/Reference-language-for-Computing-Science-Sep2016.pdf",
        "_blank"
      );
    };

    return {
      openSpec,

      bgFill:
        getComputedStyle(document.documentElement).getPropertyValue(
          "--bg-light"
        ) + "55",
      brushColor: getComputedStyle(document.documentElement).getPropertyValue(
        "--accent-300"
      ),

      icons: {
        code: codeIcon,
        github: githubIcon,
      },
    };
  },
});
