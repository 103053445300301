
import { defineComponent, onMounted, ref } from "vue";

import { Icon } from "@iconify/vue";
import closeIcon from "@iconify-icons/feather/x";

export default defineComponent({
  name: "CToast",
  components: {
    Icon,
  },
  props: {
    text: {
      type: String,
      default: "This is some example text.",
    },
    duration: {
      type: Number,
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const toast = ref(document.createElement("div"));

    const hideToast = () => {
      if (!toast.value || toast.value.classList.contains("exit")) return;

      toast.value.classList.add("exit");
      emit("close");
      setTimeout(() => emit("slide-out"), 600);
    };

    onMounted(() => {
      if (props.duration) {
        setTimeout(() => {
          hideToast();
        }, props.duration);
      }
    });

    return {
      toast,
      hideToast,
      icons: {
        close: closeIcon,
      },
    };
  },
});
