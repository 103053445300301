import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mr-2 text-sm font-mono font-medium" }
const _hoisted_2 = { class: "flex flex-col overflow-y-scroll h-full scroll pr-2 text-bg-light" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_c_modal = _resolveComponent("c-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", _mergeProps(_ctx.$attrs, {
      class: "fixed bottom-5 right-5 text-t-main h-6 flex items-center transform scale-110 opacity-40 hover:opacity-75 transition-opacity duration-300 outline-none",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = true))
    }), [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.chosenTheme), 1),
      _createVNode(_component_Icon, {
        class: "w-6 h-6",
        icon: _ctx.icons.theme
      }, null, 8, ["icon"])
    ], 16),
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_c_modal, {
          key: 0,
          closeable: "",
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModal = false)),
          class: "max-w-2xl w-10/12 h-5/6"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.themes, (theme) => {
                return (_openBlock(), _createElementBlock("button", {
                  key: theme,
                  class: _normalizeClass(["flex py-1.5 px-2 my-1 rounded-sm text-sm font-mono font-medium opacity-70 hover:opacity-100 hover:text-bg-dark hover:bg-input-blur-dark transition-all duration-300 outline-none", _ctx.chosenTheme === theme ? 'opacity-100 text-primary-600' : '']),
                  onClick: ($event: any) => (_ctx.changeTheme(theme))
                }, _toDisplayString(theme), 11, _hoisted_3))
              }), 128))
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}