import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-88961468"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "toast",
  class: "toast fixed top-3 right-0 left-0 z-40 mx-auto bg-bg-dark max-w-3xl h-12 rounded-lg flex items-center justify-center px-11",
  style: {"width":"95%"},
  id: "toast"
}
const _hoisted_2 = { class: "text-bg-light text-sm font-semibold opacity-90 overflow-ellipsis overflow-hidden whitespace-nowrap font-mono" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.text), 1),
    _createElementVNode("button", {
      class: "absolute w-7 h-7 text-bg-light my-auto right-3 focus:outline-none focus:opacity-100 opacity-50 hover:opacity-100 cursor-pointer transition-opacity duration-200",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hideToast && _ctx.hideToast(...args))),
      id: "closeToastBtn"
    }, [
      _createVNode(_component_Icon, {
        icon: _ctx.icons.close,
        class: "w-full h-full"
      }, null, 8, ["icon"])
    ])
  ], 512))
}